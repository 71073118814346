/** 接下来在这里面维护所有公用方法，比如 数字过度动画 */

/**
 * 防抖函数-目前仅是延迟执行版本
 * @param fn function  要做处理的防抖函数
 * @param delay number 延迟间隔-毫秒级
 */
export function debounce(fn, delay) {
  const delays = delay || 300
  let timer
  return function () {
    const th = this
    const args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(function () {
      timer = null
      fn.apply(th, args)
    }, delays)
  }
}

/**
 * 数字过渡动画-目前版本-默认一秒之内完成过渡动画
 * @param current number 当前值
 * @param target number 目标值
 * @param _this 传递 当前实例指向 this
 * @param property string _this 和 property 是因为要把需要改变的属性传递进去，因为函数传参基础数据类型是值拷贝而不是值引用
 * @param step number 当前版本支持 1 0.1 0.01 分别对应 整数变化、一位小数、两位小数
 */
export function numAnimation(current, target, _this, property, step = 1, totalTime = 1000, duration = 20) {
  let timer
  clearInterval(timer)
  let ternialStep = 0
  if (target >= current) {
    ternialStep = (target - current) / (totalTime / duration)
  } else {
    ternialStep = (current - target) / (totalTime / duration)
  }

  if (ternialStep > 10) {
    ternialStep = parseInt(ternialStep).toString().split('')
    ternialStep[ternialStep.length - 1] = 1
    ternialStep = parseInt(ternialStep.join(''))
  } else {
    ternialStep = Math.ceil(ternialStep) || 1
  }
  if (current < target) {
    timer = setInterval(() => {
      if (step === 0.1) {
        _this[property] = (_this[property] * 1000 + (step + ternialStep) * 1000) / 1000
      } else if (step === 0.01) {
        _this[property] = (_this[property] * 1000 + (step + ternialStep) * 1000) / 1000
      } else {
        _this[property] = _this[property] + ternialStep
      }
      if (_this[property] >= target) {
        _this[property] = target
        clearInterval(timer)
      }
    }, duration)
  } else if (current > target) {
    timer = setInterval(() => {
      if (step === 0.1) {
        _this[property] = (_this[property] * 1000 - (step + ternialStep) * 1000) / 1000
      } else if (step === 0.01) {
        _this[property] = (_this[property] * 1000 - (step + ternialStep) * 1000) / 1000
      } else {
        _this[property] = _this[property] - ternialStep
      }
      if (_this[property] <= target) {
        _this[property] = target
        clearInterval(timer)
      }
    }, duration)
  }
}

// 每三个数字加一个逗号
export function handleNum(n) {
  if (isNaN(n)) {
    return n
  }
  var b = parseInt(n).toString()
  if (isNaN(b)) {
    return b
  }
  var len = b.length
  if (len < 4) {
    return b
  }
  var r = len % 3
  return r > 0 ? b.slice(0, r) + ',' + b.slice(r, len).match(/\d{3}/g).join(',') : b.slice(r, len).match(/\d{3}/g).join(',')
}

/**
 * 校验只要是数字（包含正负整数，0以及正负浮点数）就返回true
 * 不允许输入特殊字符
 **/
export function isNumber(val) {
  var regPos = /^[0-9]+.?[0-9]*/ // 判断是否是数字。
  if (regPos.test(val)) {
    var flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？%]")
    if (flag.test(val)) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

// 深拷贝
export function deepCopy(target) {
  if (typeof target === 'object') {
    const result = Array.isArray(target) ? [] : {}
    for (const key in target) {
      if (typeof target[key] === 'object') {
        result[key] = deepCopy(target[key])
      } else {
        result[key] = target[key]
      }
    }
    return result
  }
  return target
}
export function getData() {
  var myDate = new Date()
  let Y = myDate.getFullYear() //年
  if (myDate.getMonth() + 1 < 10) {
    //月
    var M = myDate.getMonth() + 1
    M = '0' + M
  } else {
    var M = myDate.getMonth() + 1
  }
  if (myDate.getDate() < 10) {
    //日
    var D = myDate.getDate()
    D = '0' + D
  } else {
    var D = myDate.getDate()
  }
  return (myDate = `${Y}-${M}-${D}`) //2019-09-23
}
export function getEquipmentType() {
  let userAgentInfo = navigator.userAgent
  let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  let getArr = Agents.filter(i => userAgentInfo.includes(i))
  return getArr.length ? 'APP' : 'WEB'
}
/**
 * js数值计算 示例：funCalc([1.02,3,4])
 * @param {*} arrList 参数数组
 * @param {*} operator 操作符：1加法 2乘法
 * @param {*} precision 精度 默认2位
 */
export function funCalc(arrList, operator = 1, precision = 2) {
  if (arrList.length === 0) return 0
  // 计算最大数度值,避免精度丢失，先扩大位数，再缩小
  let mPow = Math.pow(10, precision) // 默认2位小数
  let total = 0
  if (operator == 2) {
    total = 1
    mPow = 1
  }
  for (const item of arrList) {
    let tem = 0
    if (item) {
      tem = parseFloat(item) ?? 0 // parseFloat处理，转化失败时给0
      tem = !isNaN(tem) ? tem : 0 // 处理 NaN 情况
    }
    switch (operator) {
      case 1:
        total += tem * mPow
        break
      case 2:
        total *= tem * mPow
        break
      default:
        total += tem * mPow
    }
  }
  // toFixed部分浏览器会用问题，toFixed它是一个四舍六入五成双的诡异的方法(也叫银行家算法)，"四舍六入五成双"含义：对于位数很多的近似数，当有效位数确定后，其后面多余的数字应该舍去，只保留有效数字最末一位，这种修约（舍入）规则是“四舍六入五成双”，也即“4舍6入5凑偶”这里“四”是指≤4 时舍去，"六"是指≥6时进上，"五"指的是根据5后面的数字来定，当5后有数时，舍5入1；当5后无有效数字时，需要分两种情况来讲：①5前为奇数，舍5入1；②5前为偶数，舍5不进。（0是偶数）
  const res = (total / mPow).toFixed(precision)
  // // 修复toFixed
  // const res = parseInt(total + 0.5, 10) / times;

  return res
}

// 查询图片链接是否有效
export function checkImgExists(imgurl) {
  return new Promise(function (resolve, reject) {
    var ImgObj = new Image()
    ImgObj.src = imgurl
    ImgObj.onload = function (res) {
      resolve(true)
    }
    ImgObj.onerror = function (err) {
      resolve(false)
    }
  })
}

// 计算优惠后的价格
/**
 * @param {*} appendArr 可叠加的折扣集合
 * @param {*} appendNoArr 不可叠加的折扣集合
 * @param {*} itemId 课程组id
 * @param {*} price 课程组原价
 */
export function getDiscountPrice(appendArr, appendNoArr, itemId, price) {
  let showList = [] // 最终所使用的折扣
  let showNum = 0 // 最终优惠价格
  let appendItemList = appendArr.filter(item => item.goodsId == itemId)  // 可叠加
  let appendNoItemList = appendNoArr.filter(item => item.goodsId == itemId) // 不可叠加
  let appendPrice = appendItemList.reduce((reduce, item) => { // 可叠加的折扣计算出来的最后金额
    const discount = item.discountNum / 100
    // console.log('reduce * discount',Math.floor(reduce * discount))
    return Math.ceil(reduce * discount)
  }, price)
  let appendNoPrice = Math.min(...appendNoItemList.map(item => item.discountNum)) / 100 * price // 不可叠加的折扣计算出来的最后金额
  appendNoPrice = appendNoPrice.toFixed(0)
  showNum = Number(appendPrice) > Number(appendNoPrice) ? Number(appendNoPrice) : Number(appendPrice)
  if(Number(appendPrice) > Number(appendNoPrice)){
    const minValue = Math.min(...appendNoItemList.map(item => item.discountNum))
    showList = appendNoItemList.filter(item => item.discountNum == minValue)
  }else{
    showList = appendItemList
  }
  // console.log('可叠加所有的折扣集合--', appendItemList)
  // console.log('不可叠加的折扣--', appendNoItemList)
  // console.log('比较最便宜的价格', price, Number(appendPrice), Number(appendNoPrice), '相比较--', Number(appendPrice) > Number(appendNoPrice) ? Number(appendNoPrice) : Number(appendPrice))
  return { showNum, showList }
}
